.flat-button {
    padding: 0;
    margin: 0;
    color: inherit;
    text-decoration: inherit;
    font-size: inherit;
    line-height: inherit;
    height: inherit;
    background: none;
    border: none;
}
